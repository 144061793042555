<template lang="pug">
  .filters
    .row
      .filter.col
        p.filter-title {{ $t("company_system.car_class_settings.car_type") }}
        AppDropdown.car-type(
          checkbox
          batch-select
          multiple
          allow-empty
          value-key="id"
          title-key="name"
          :value="filters.carTypes"
          :items="translatedCarTypes"
          @select="$emit('change-car-types', $event)"
        )
      .filter.col.search
        AppSearch(
          :value="filters.searchValue"
          @update="$emit('update-search-value', $event)"
        )
    ActionBar(
      @add-new="$emit('add-new')"
    )
</template>

<script>
  // misc
  import { extractTranslatedAttribute } from "@/helpers/common"
  import { map } from "lodash-es"

  // store modules
  import carTypesModule from "@/config/store/company_system/car_class_settings/car_types"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  const carTypesMixin = withStoreModule(carTypesModule, {
    name: "carTypesMixin",
    readers: { carTypes: "items" },
    actions: { fetchCarTypes: "FETCH_ITEMS" }
  })

  export default {
    components: {
      ActionBar: () => import("../ActionBar"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [carTypesMixin],

    mounted() {
      this.fetchCarTypes()
    },

    props: {
      filters: {
        type: Object,
        default: () => new Object()
      }
    },

    computed: {
      translatedCarTypes() {
        return map(this.carTypes, carType => {
          return {
            id: carType.id,
            name: extractTranslatedAttribute(carType, "type_name")
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .filters
    .row
      margin-left: -22px

    .filter
      +filter-title

      max-width: 21%
      padding-top: 20px

    .search
      margin-top: 32px
</style>
